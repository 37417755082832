import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useContext, useState } from "react";
import { PostsContext } from "../pages/Home";
import ModuleBox from "./ModuleBox";
import { useMediaQuery } from "react-responsive";
import { BASE_URL } from "../config";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

function CreatePost(props) {
    const { username, setUploadedPosts, uploadedPosts } = useContext(PostsContext)

    const [posting, setPosting] = useState(false)
    const [title, _setTitle] = useState("")
    const [content, _setContent] = useState("")

    const queryClient = useQueryClient()

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const setTitle = evt => {
        _setTitle(evt.target.value)
    }

    const setContent = evt => {
        _setContent(evt.target.value)
    }

    const makePost = () => {
        setPosting(true)
        fetch(`${BASE_URL}/posts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title,
                content,
                username
            })
        })
            .then(res => res.text())
            .then(data => {
                console.debug("received response data: ", data)
                if (data !== 'success') {
                    toast.error(`Error creating post: ${data}`)
                } else {
                    _setTitle("")
                    _setContent("")
                    queryClient.invalidateQueries("posts").then(() => {
                        setUploadedPosts([...uploadedPosts, {
                            title,
                            content,
                            username,
                            time: new Date().getTime()
                        }])
                    })
                }
            })
            .catch(error => {
                toast.error(`Error creating post: Unknown Error`)
                console.error(error)
            })
            .finally(() => {
                setPosting(false)
            })
    }

    return <>
        <ModuleBox width={'100%'} marginRight={0} display={'flex'} flexDirection={isTabletOrMobile ? 'column' : 'row'}
            justifyContent={'space-between'}>
            <Box width={'100%'}>
                <Typography variant={'h5'}>Create Post</Typography>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} mt={1}
                    paddingRight={isTabletOrMobile ? '0' : '20%'}>
                    <TextField label={'Title'} value={title} onChange={setTitle}/>
                    <TextField label={'Content'} value={content} onChange={setContent} multiline={true}
                        fullWidth={!isTabletOrMobile}/>
                </Box>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} whiteSpace={'nowrap'}>
                <Button variant={'contained'} disabled={posting} onClick={makePost}>
                    Post to feed!
                </Button>
            </Box>
        </ModuleBox>
    </>
}

export default CreatePost
