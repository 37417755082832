import { Box, makeStyles, Typography } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import { QueryClient, QueryClientProvider } from "react-query";
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient()

const useStyles = makeStyles({
    navBar: {
        margin: 0,
        listStyle: "none",
        display: "flex",
        flexDirection: "row",
        "& > li > a": {
            textDecoration: "none",
            color: "black"
        },
        padding: "16px 24px",
        background: 'white',
        borderBottom: '1px solid rgb(240,241,242)'
    },
    contentContainer: {
        height: '100%',
        background: 'rgb(248,249,250)'
    }
});

function App() {
    const classes = useStyles();

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <ToastContainer/>
                <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                    <nav>
                        <ul className={classes.navBar}>
                            <li>
                                <Link to="/"><Typography variant={'h4'}>Home</Typography></Link>
                            </li>
                        </ul>
                    </nav>

                    <div className={classes.contentContainer}>
                        <Switch>
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch>
                    </div>
                </Box>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
