import Posts from "../components/Posts";
import CreatePost from "../components/CreatePost";
import SelectAccount from "../components/SelectAccount";
import { createContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { DEFAULT_USERNAME } from "../config";

const PostsContext = createContext({})

function Home(props) {
    const [username, setUsername] = useState(DEFAULT_USERNAME)
    const [uploadedPosts, setUploadedPosts] = useState([])

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    return <>
        <PostsContext.Provider value={{
            username,
            setUsername,
            uploadedPosts,
            setUploadedPosts
        }}>
            <Box display={'flex'} flexDirection={isTabletOrMobile ? 'column' : 'row'}>
                <CreatePost/>
                <SelectAccount/>
            </Box>
            <Posts/>
        </PostsContext.Provider>
    </>
}

export { Home as default, PostsContext }
