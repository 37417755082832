import { useContext } from "react";
import { PostsContext } from "../pages/Home";
import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import ModuleBox from "./ModuleBox";

const useStyles = makeStyles({
    usernameLabel: {
        whiteSpace: 'nowrap',
        marginRight: 6
    }
});

function SelectAccount(props) {
    const { setUsername, username } = useContext(PostsContext)

    const classes = useStyles()

    return <ModuleBox display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Typography variant={'h5'}><span className={classes.usernameLabel}>Set username: </span></Typography>
        <TextField variant="outlined" value={username} onChange={(evt) => setUsername(evt.target.value)}
            size={'small'}/>
    </ModuleBox>
}

export default SelectAccount
