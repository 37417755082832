import { useInfiniteQuery } from "react-query";
import { Fragment, useContext } from "react";
import { BASE_URL } from "../config";
import ModuleBox from "./ModuleBox";
import Post from "./Post";
import { Box, Button, Typography } from "@material-ui/core";
import { PostsContext } from "../pages/Home";

const makeKey = post => `${post.username}:${post.time}`
const makeContentKey = post => `${post.title}:${post.content}:${post.username}`

function Posts(props) {
    const fetchPosts = ({ pageParam }) =>
        fetch(`${BASE_URL}/paginated-posts${pageParam ? `?cursor=${pageParam}` : ""}`).then(res => res.json())

    const { uploadedPosts } = useContext(PostsContext)

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery('posts', fetchPosts, {
        getNextPageParam: (lastPage, posts) => lastPage.nextCursor,
    })

    const posts = (data ? data.pages : [] || []).reduce((acc, cur) => [...acc, ...cur.posts], [])
    const existingKeys = posts.map(post => makeContentKey(post))
    for (const _post of uploadedPosts) {
        const _postKey = makeContentKey(_post)
        if (!existingKeys.includes(_postKey)) {
            existingKeys.push(_postKey)
            posts.push(_post)
        }
    }
    posts.sort((a, b) => b.time - a.time)

    return <ModuleBox marginTop={0}>
        <Typography variant={'h5'}>User Posts: </Typography>
        {status === 'loading'
            ? <p>Loading...</p>
            : status === 'error'
                ? <p>Error: {error.message}</p>
                : <>
                    {posts.map(post => <Post {...post} key={makeKey(post)}/>)}
                    <Box mt={2}>
                        <Button
                            variant={'contained'}
                            onClick={() => fetchNextPage()}
                            disabled={!hasNextPage || isFetchingNextPage}
                        >
                            {isFetchingNextPage
                                ? 'Loading more posts...'
                                : hasNextPage
                                    ? 'Load More Posts'
                                    : 'Nothing more to load'}
                        </Button>
                    </Box>
                    <Box mt={2}>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</Box>
                </>
        }
    </ModuleBox>
}

export default Posts
