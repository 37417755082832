import { Box, Typography } from "@material-ui/core";

function Post(props){
    return <Box display={'flex'} flexDirection={'column'} mt={2}>
        <Typography variant={'h4'}>{props.title}</Typography>
        <Typography variant={'h6'}>by {props.username}</Typography>
        <Typography variant={'body1'}>{props.content}</Typography>
        <Typography variant={'body2'}>at {props.time}</Typography>
    </Box>
}

export default Post
